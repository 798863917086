<template>
  <div>
    <HeroSmall :page_title="page_title" :leadline="leadline" />
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center my-5">
          <div class="col-lg-9" >
            <card shadow body-classes="py-5">
              <h3 slot="header" class="heading-title text-center">{{ job.title }}</h3>
              <div v-html="job.description" class="mb-4"></div>
              <section>
                <hr>
                <h4>Qualifications</h4>
                <ul>
                  <li v-for="(q, index) in job.qualifications" :key="index">{{ q.desc }}</li>
                </ul>
                <hr>
                <h4>Responsibilities</h4>
                <ul>
                  <li v-for="(r, index) in job.responsibilities" :key="index">{{ r.desc }}</li>
                </ul>
                <router-link class="btn btn-success" :to="`/employment?job_title=${job.title}&job_key=${job.key}`">Apply Now</router-link>
              </section>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import HeroSmall from '@/views/components/HeroSmall.vue'
  import Card from '@/components/Card.vue'

  import {
    jobsRef
  } from '@/firebase'
  export default {
    name: 'job',
    components: {
      HeroSmall,
      Card
    },
    data: () => ({
      show_more:'',
      page_title: 'Job Listing',
      leadline: '',
      job_key: '',
      job: {}
    }),
    methods: {
      getJobs(key) {
        jobsRef.child(key).once('value', res => {
          let obj = res.val()
          if (!obj.active) {
            this.$router.push({path: '/jobs'})
          } else {
            this.job = res.val()
          }
        })
      }
    },
    created() {
      this.job_key = this.$route.params.id
      this.getJobs(this.$route.params.id)
    }
  }
</script>

<style>

</style>